<ng-container *ngIf="menuItems$ | async as menuItems">
  <p-menu styleClass="alg-top-right-menu" #menu [popup]="true" [model]="menuItems"></p-menu>
  <p-button
      *ngIf="userLogin$ | async as userLogin"
      icon="pi pi-chevron-down"
      iconPos="right"
      [label]="userLogin"
      (click)="menu.toggle($event)"
      styleClass="alg-top-right-menu-button"
  ></p-button>
</ng-container>
