<div class="modebar-wrapper editor-bar-wrapper" *ngIf="watchedGroup$ | async as watchedGroup">
  <span class="modebar-left-icon">
    <i class="fa fa-eye"></i>
  </span>
  <span class="modebar-title">
    <ng-container i18n>Observation Mode</ng-container>
    <ng-container *ngIf="watchedGroup.name">
      : <a
        class="modebar-link alg-link white-color hover-underline"
        [routerLink]="watchedGroup.route | groupLink">
        {{ watchedGroup.name }}
      </a>
    </ng-container>
  </span>

  <button pButton type="button" icon="pi pi-times" class="p-button-rounded" (click)="onCancelClick()"></button>

  <div class="right-border-adapter">&nbsp;</div>
</div>
