<ng-container *ngIf="currentLanguage">
  <p-dialog
    *ngIf="languageMismatch$ | async as languageMismatch"
    i18n-header header="Language mismatch"
    [visible]="true"
    [style]="{width: '50vw'}"
    [modal]="true"
    [closeOnEscape]="false"
    [closable]="false"
    >
    <div class="spinner-overlay" *ngIf="updating">
      <alg-loading></alg-loading>
    </div>

    <ng-container *ngIf="languageMismatch.userDefaultLanguageIsSupported">
      <div i18n>
        The current language "{{ currentLanguage }}" does not correspond to your account's default language "{{ languageMismatch.userDefaultLanguage }}".
        Do you want to continue in this language?
      </div>

      <div class="actions">
        <p-button
          type="button"
          (click)="onUpdateUserLanguage(currentLanguage)"
        >
          <ng-template pTemplate="content">
            <span i18n>
              Continue in "{{ currentLanguage }}" and change my default language to "{{ currentLanguage }}" for this platform
            </span>
          </ng-template>
        </p-button>
        <p-button
          type="button"
          (click)="onVisitPlatformInUserLanguage(languageMismatch.userDefaultLanguage)"
        >
          <ng-template pTemplate="content" i18n>
            Visit the platform in "{{ languageMismatch.userDefaultLanguage }}"
          </ng-template>
        </p-button>
      </div>
    </ng-container>

    <ng-container *ngIf="!languageMismatch.userDefaultLanguageIsSupported">
      <div i18n>
        The platform does not exist in your default language "{{ languageMismatch.userDefaultLanguage }}".
      </div>
      <div class="actions">
        <p-button
          type="button"
          (click)="onUpdateUserLanguage(currentLanguage)"
        >
          <ng-template pTemplate="content" i18n>
            Continue in "{{ currentLanguage }}" and change my default language to "{{ currentLanguage }}" for this platform.
          </ng-template>
        </p-button>
      </div>
    </ng-container>

    <small i18n>The language can always be modified through your profile page.</small>
  </p-dialog>
</ng-container>
