<ng-container *ngIf="currentMode$ | async as currentMode">
  <alg-editor-bar *ngIf="currentMode === 'editing'" (cancel)="onEditCancel()"></alg-editor-bar>
  <alg-observation-bar *ngIf="currentMode !== 'editing' && (watchedGroup$ | async)"></alg-observation-bar>
</ng-container>

<ng-container *ngIf="currentContent$ | async as currentContent">
  <div class="breadcrumb-bar" *ngIf="!fullFrameContent?.active && !scrolled">
    <alg-breadcrumb [contentBreadcrumb]="currentContent?.breadcrumbs"></alg-breadcrumb>
  </div>
  <div class="full-frame-content-top-bar" *ngIf="fullFrameContent?.active || scrolled">
    <ng-container *ngIf="currentContent.score">
      <alg-score-ring
        [bestScore]="currentContent.score.bestScore"
        [currentScore]="currentContent.score.currentScore"
        [isValidated]="currentContent.score.isValidated"
        [diameter]="34"
      ></alg-score-ring>
    </ng-container>
    <div class="right-pane-title">
      <p *ngIf="currentContent && currentContent.title">{{ currentContent.title }}</p>
    </div>
    <alg-neighbor-widget
      class="neighbor-widget"
      *ngIf="navigationNeighbors$ | async as navigationNeighbors"
      [navigationMode]="{
        parent: !!navigationNeighbors?.isReady && !!navigationNeighbors?.data?.parent,
        left: !!navigationNeighbors?.isReady && !!navigationNeighbors?.data?.previous,
        right: !!navigationNeighbors?.isReady && !!navigationNeighbors?.data?.next
      }"
      (parent)="navigationNeighbors?.data?.parent?.navigateTo()"
      (left)="navigationNeighbors?.data?.previous?.navigateTo()"
      (right)="navigationNeighbors?.data?.next?.navigateTo()"
    ></alg-neighbor-widget>
  </div>
</ng-container>
