<div class="neighbor-widget" *ngIf="navigationMode">
  <div class="neighbor-widget-wrapper">
    <span class="p-buttonset">
      <button
        pButton
        type="button"
        icon="fa fa-arrow-up"
        class="neighbor-widget-button up-button"
        (click)="parent.emit()"
        *ngIf="navigationMode.parent"
        @buttonAnimation
      ></button>
      <button
        pButton
        type="button"
        icon="fa fa-chevron-left"
        class="neighbor-widget-button"
        (click)="left.emit()"
        [disabled]="!navigationMode.left"
      ></button>
      <button
        pButton
        type="button"
        icon="fa fa-chevron-right"
        class="neighbor-widget-button"
        (click)="right.emit()"
        [disabled]="!navigationMode.right"
      ></button>
    </span>
  </div>
</div>
