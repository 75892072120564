<ng-container *ngIf="type !== 'vertical'">
  <div
    class="progress-bar-wrapper"
    [ngStyle]="{ 'height.rem': progressHeight, 'background-color': color }"
  >
    <div
      class="progress-current"
      [ngStyle]="{
        'width.%': bestScore,
        'background-color': currentColor
      }"
    ></div>
    <div
      class="progress-displayed"
      [ngStyle]="{
        'width.%': currentScore,
        'background-color':displayColor
      }"
    ></div>
    <div class="progress-label" *ngIf="type === 'thick-horizontal'">
      {{ currentScore | number: '1.0-1'}}%
    </div>
  </div>
</ng-container>

<ng-container *ngIf="type === 'vertical'">
  <div
    class="progress-bar-wrapper vertical"
    [ngStyle]="{'background-color': color }"
  >
    <div
      class="progress-current vertical-progress"
      [ngStyle]="{
        'height.%': bestScore,
        'background-color': currentColor
      }"
    ></div>
    <div
      class="progress-displayed vertical-progress"
      [ngStyle]="{
        'height.%': currentScore,
        'background-color': displayColor
      }"
    ></div>
    <!-- <div class="progress-label vertical-label">
      {{ displayScore }}%
    </div> -->
  </div>
</ng-container>
