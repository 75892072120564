
<div class="tree-header" *ngIf="data && data.parent" (click)="navigateToParent()">
  <span class="tree-header-handle">
    <i class="fa fa-chevron-left"></i>
    <div class="icon-folder"><img src="assets/images/tree-folder.svg" alt=""></div>
  </span>
  <span class="tree-header-title">{{ data.parent.title }}</span>
</div>

<div class="tree-header" *ngIf="!data?.parent && elementType === 'group'" [routerLink]="['groups', 'mine']" routerLinkActive="selected-header">
  <span class="tree-header-mygroups">
    <i class="fa fa-users"></i>
  </span>
  <span class="tree-header-title" i18n>My groups</span>
</div>

<p-tree styleClass="alg-tree-nav" [value]="nodes" i18n-emptyMessage emptyMessage="There is no content to display">

  <!-- "chapter" type -->
  <ng-template let-node pTemplate="chapter">
    <span
      id="nav-{{ node.data.route.id }}"
      class="node-tree-item folder"
      [ngClass]="{ expanded: node.expanded, locked: node.data.locked, current: node.partialSelected }"
    >
      <span class="node-item-branch">
        <span class="top-branch">
          <span class="mask-branch"></span>
        </span>
        <span class="bottom-branch folder-branch">
          <span class="mask-branch"></span>
        </span>
      </span>
      <span class="node-item-content" (click)="selectNode(node)">
        <span class="node-select">
          <alg-score-ring
            *ngIf="node.data.score; else folderNoScore"
            [currentScore]="node.data.score.currentScore"
            [bestScore]="node.data.score.bestScore"
            [isValidated]="node.data.score.validated"
            [diameter]="30"
            [forTree]="true"
            [isDark]="true"
            [icon]="node.expanded ? 'folder-open' : 'folder'"
            [forDarkBg]="!node.partialSelected"
          ></alg-score-ring>
          <ng-template #folderNoScore>
            <i class="fa" [ngClass]="{ 'fa-folder': !node.expanded, 'fa-folder-open': node.expanded }"></i>
          </ng-template>
        </span>
        <span class="node-label">
          <span class="label-container">
            <span class="node-label-title">
              {{ node.label }}
              <span *ngIf="!['Base', 'ContestParticipants'].includes(node.data.associatedGroupType) && node.data.associatedGroupName">
                (<span i18n="@@viaGroup">via group</span> "{{ node.data.associatedGroupName }}")
              </span>
            </span>
            <span
              class="node-state"
              *ngIf="node.data.locked"
              i18n-pTooltip pTooltip="Your current access rights do not allow you to list the content of this chapter."
            >
              <i class="fa fa-lock"></i>
            </span>
          </span>
        </span>
      </span>
    </span>
  </ng-template>

  <!-- "skill-folder" type -->
  <ng-template let-node pTemplate="skill-folder">
    <span
      id="nav-{{ node.data.route.id }}"
      class="node-tree-item folder"
      [ngClass]="{ expanded: node.expanded, locked: node.data.locked, current: node.partialSelected }"
    >
      <span class="node-item-branch">
        <span class="top-branch">
          <span class="mask-branch"></span>
        </span>
        <span class="bottom-branch folder-branch">
          <span class="mask-branch"></span>
        </span>
      </span>
      <span class="node-item-content" (click)="selectNode(node)">
        <span class="node-select">
          <i class="fa" [ngClass]="{ 'fa-folder': !node.expanded, 'fa-folder-open': node.expanded }"></i>
        </span>
        <span class="node-label">
          <span class="label-container">
            <span class="node-label-title">
              {{ node.label }}
              <span *ngIf="!['Base', 'ContestParticipants'].includes(node.data.associatedGroupType) && node.data.associatedGroupName">
                (<span i18n="@@viaGroup">via group</span> "{{ node.data.associatedGroupName }}")
              </span>
            </span>
            <span class="node-state" *ngIf="node.data.locked">
              <i class="fa fa-lock"></i>
            </span>
          </span>
          <alg-skill-progress
            *ngIf="elementType === 'skill' && node.data.score"
            [bestScore]="node.data.score.bestScore"
            [currentScore]="node.data.score.currentScore"
            [type]="'thin-horizontal'"
          ></alg-skill-progress>
        </span>
      </span>
    </span>
  </ng-template>

  <!-- 'skill-leaf' type -->
  <ng-template let-node pTemplate="skill-leaf">
    <span
      id="nav-{{ node.data.route.id }}"
      class="node-tree-item folder"
      [ngClass]="{ locked: node.data.locked, current: node.partialSelected }"
    >
      <span class="node-item-branch">
        <span class="top-branch">
          <span class="mask-branch"></span>
        </span>
        <span class="bottom-branch">
          <span class="mask-branch"></span>
        </span>
      </span>
      <span class="node-item-content" (click)="selectNode(node)">
        <span class="node-select">
          <i class="pi" [class.pi-circle-on]="!node.partialSelected" style="font-size: 0.6rem;"></i>
          <i class="pi" [class.pi-check]="node.partialSelected" style="font-size: 1.2rem;"></i>
        </span>
        <span class="node-label">
          <span class="label-container">
            <span class="node-label-title">
              {{ node.data.title }}
              <span *ngIf="!['Base', 'ContestParticipants'].includes(node.data.associatedGroupType) && node.data.associatedGroupName">
                (<span i18n="@@viaGroup">via group</span> "{{ node.data.associatedGroupName }}")
              </span>
            </span>
            <span
              class="node-state"
              *ngIf="node.data.locked"
              i18n-pTooltip pTooltip="Your current access rights do not allow you to list the content of this skill."
            >
              <i class="fa fa-lock"></i>
            </span>
          </span>
          <alg-skill-progress
              *ngIf="elementType === 'skill' && node.data.score"
              [bestScore]="node.data.score.bestScore"
              [currentScore]="node.data.score.currentScore"
              [type]="'thin-horizontal'"
          ></alg-skill-progress>
        </span>
      </span>
    </span>
  </ng-template>

  <!-- "group-folder" type -->
  <ng-template let-node pTemplate="group">
    <span
      id="nav-{{ node.data.route.id }}"
      class="node-tree-item folder"
      [ngClass]="{ expanded: node.expanded, locked: node.data.locked, current: node.data && node.partialSelected }"
    >
      <span class="node-item-branch">
        <span class="top-branch">
          <span class="mask-branch"></span>
        </span>
        <span class="bottom-branch folder-branch">
          <span class="mask-branch"></span>
        </span>
      </span>
      <span class="node-item-content" (click)="selectNode(node)">
        <span class="node-select">
          <i class="fa fa-users" *ngIf="node.data.type !== 'User'"></i>
          <i class="fa fa-user" *ngIf="node.data.type === 'User'"></i>
        </span>
        <span class="node-label">
          <span class="label-container">
            <span class="node-label-title">
              {{ node.label }}
            </span>
            <span class="node-state">
              <i
                *ngIf="node.data.groupRelation.managership !== 'none'"
                class="fa fa-chalkboard-teacher"
                [class.fade]="node.data.groupRelation.managership === 'descendant'"
                i18n-pTooltip pTooltip="{{ node.data.groupRelation.managership === 'descendant' ? 'You are a manager of one of the descendant of the group' : 'You are a manager of the group' }}"
              ></i>
              <i
                *ngIf="node.data.groupRelation.isMember"
                class="fa fa-user-friends"
                i18n-pTooltip pTooltip="You are a member of the group"
              ></i>
              <i *ngIf="node.data.status === 'error'" class="fa fa-exclamation-triangle"></i>
            </span>
          </span>
        </span>
      </span>
    </span>
  </ng-template>

  <!-- 'task-course' type -->
  <ng-template let-node pTemplate="task-course">
    <span
      id="nav-{{ node.data.route.id }}"
      class="node-tree-item folder"
      [ngClass]="{ locked: node.data.locked, current: node.partialSelected }"
    >
      <span class="node-item-branch">
        <span class="top-branch">
          <span class="mask-branch"></span>
        </span>
        <span class="bottom-branch">
          <span class="mask-branch"></span>
        </span>
      </span>
      <span class="node-item-content" (click)="selectNode(node)">
        <span class="node-select">
          <alg-score-ring
            *ngIf="node.data.score !== undefined; else leafNoScore"
            [currentScore]="node.data.score.currentScore"
            [bestScore]="node.data.score.bestScore"
            [isValidated]="node.data.score.validated"
            [diameter]="30"
            [forTree]="true"
            [isDark]="true"
            [forDarkBg]="!node.partialSelected"
          ></alg-score-ring>
          <ng-template #leafNoScore>
            <i class="pi" [class.pi-circle-on]="!node.partialSelected" style="font-size: 0.6rem;"></i>
            <i class="pi" [class.pi-check]="node.partialSelected" style="font-size: 1.2rem;"></i>
          </ng-template>
        </span>
        <span class="node-label">
          <span class="label-container">
            <span class="node-label-title">
              {{ node.data.title }}
              <span *ngIf="!['Base', 'ContestParticipants'].includes(node.data.associatedGroupType) && node.data.associatedGroupName">
                (<span i18n="@@viaGroup">via group</span> "{{ node.data.associatedGroupName }}")
              </span>
            </span>
            <span
              class="node-state"
              *ngIf="node.data.locked"
              i18n-pTooltip pTooltip="Your current access rights do not allow you to start the activity."
            >
              <i class="fa fa-lock"></i>
            </span>
          </span>
        </span>

      </span>
    </span>
  </ng-template>

</p-tree>
