<div class="nav-header" *ngIf="!compactMode">
  <div class="nav-control">
    <span class="platform-info">
      <span class="platform-name">{{ title }}</span>
    </span>
    <span class="nav-collapse" (click)="setFullFrameContent()" tabindex="0">
      <i class="fas fa-chevron-circle-left"></i>
    </span>
  </div>
</div>

<div class="nav-control alt" *ngIf="compactMode">
  <span class="nav-collapse" (click)="unsetFullFrameContent()" tabindex="0">
    <i class="fas fa-chevron-circle-right"></i>
  </span>
</div>
