<div class="modebar-wrapper editor-bar-wrapper ">
  <span class="modebar-left-icon">
    <i class="fa fa-edit"></i>
  </span>
  <span class="modebar-title" i18n style="flex:1">
    Edit Mode
  </span>

  <button pButton type="button" icon="pi pi-times" class="p-button-rounded" (click)="onCancelClick()"></button>

  <div class="right-border-adapter">&nbsp;</div>
</div>
